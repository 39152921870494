<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Other texts</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseSection-5`"
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseSection-5`"
        visible
      >
        <div class="card-body">
          <b-tabs>
            <b-tab
              v-for="lang in langs"
              :key="lang"
            >
              <template #title>
                {{ lang.toUpperCase() }}
              </template>
              <b-tabs>
                <b-tab
                  v-if="referees"
                  active
                >
                  <template #title>
                    {{ labels['other_texts.section_for_referees'] }} <span
                      v-if="selectedTexts['referees'] || (convo.texts && convo.texts.referees)"
                      class="text-success ms-1"
                    ><i data-feather="check" /></span>
                  </template>
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['other_texts.text_for_referees'] }}</label>
                  <TextArea
                    name="texts['referees']"
                    :answer="{value: convo.texts && convo.texts[lang].referees ? convo.texts[lang].referees.text : ''}"
                    :edit="true"
                    @submitAnswer="submitAnswer($event, 'referees', lang)"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    {{ labels['other_texts.opening_text'] }} <span
                      v-if="selectedTexts['opening'] || (convo.texts && convo.texts.opening)"
                      class="text-success ms-1"
                    ><i data-feather="check" /></span>
                  </template>
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['other_texts.text_for_opening'] }}</label>
                  <TextArea
                    name="texts['opening']"
                    :answer="{value: convo.texts && convo.texts[lang].opening ? convo.texts[lang].opening.text : ''}"
                    :edit="true"
                    @submitAnswer="submitAnswer($event, 'opening', lang)"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    {{ labels['other_texts.ending_text'] }} <span
                      v-if="selectedTexts['ending'] || (convo.texts && convo.texts.ending)"
                      class="text-success ms-1"
                    ><i data-feather="check" /></span>
                  </template>
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['other_texts.text_for_ending'] }}</label>
                  <TextArea
                    name="texts['ending']"
                    :answer="{value: convo.texts && convo.texts[lang].ending ? convo.texts[lang].ending.text : ''}"
                    :edit="true"
                    @submitAnswer="submitAnswer($event, 'ending', lang)"
                  />
                </b-tab>
              </b-tabs>
            </b-tab>
          </b-tabs>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse, BTab, BTabs } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import TextArea from '../../../../convos/partials/input-types/TextArea.vue'

export default {
  components: {
    TextArea,
    BCollapse,
    BTab,
    BTabs,
  },
  props: {
    referees: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      selectedTexts: {
        es: {},
        en: {},
        ca: {},
      },
      langs: ['es', 'en', 'ca'],
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.dates`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }

    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    submitAnswer(data, type, lang) {
      this.selectedTexts[lang][type] = data
      this.$emit('saveAnswer', { id: 'texts', data: this.selectedTexts })
    },
  },
}
</script>
