<template>
  <div class="card"> <!-- Areas & Groups -->
    <div class="card-header">
      <h3 class="cart-title">
        <a
          data-action="collapse"
        >Areas & Groups evaluator for this call</a>
        <span
          class="dis-ib ms-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Go to add areas and groups"
        ><a
          class="btn btn-icon rounded-circle btn-flat-dark"
          @click="$router.push({name: 'admin.areas-groups.create'})"
        ><i data-feather="plus" /></a></span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              v-b-toggle="`collapseSection-3`"
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseSection-3`"
        visible
      >
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div
                v-for="area in areas"
                :key="area.id"
              >
                <div
                  class="form-check-success form-switch mb-2"
                >
                  <input
                    :id="`area-${area.id}`"
                    type="checkbox"
                    :name="`areas[${area.id}]['id']`"
                    class="form-check-input"
                    :checked="convo.areas ? convo.areas.find(x => x.areaId === area.id) : false"
                    @click="selectArea(area.id, area.name)"
                  >
                  <label
                    class="form-check-label ms-1"
                    for="areaSocial"
                  >
                    {{ area.name }}</label>
                </div>
                <div
                  id="areaExperimentalSublist"
                  class="ps-3"
                >
                  <!-- when parent is selected -->
                  <div
                    v-for="children in area.childrens"
                    :key="children.id"
                    class="form-check-success form-switch mb-2"
                  >
                    <input
                      :id="`areaExperimental-${area.id}`"
                      type="checkbox"
                      class="form-check-input"
                      :class="`area-${area.id}-children`"
                      :name="`areas[${children.id}]['id']`"
                      :checked="convo.areas ? convo.areas.find(x => x.areaId === children.id) : false"
                      @click="selectArea(children.id, area.name, area.id)"
                    >
                    <label
                      class="form-check-label ms-1"
                      :for="`areaExperimental-${area.id}`"
                    >
                      {{ area.name }} - {{ children.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <h4>Moderators</h4>
          <div
            v-for="area in selectedAreas"
            :key="area.id"
            class="mb-1"
          >
            <label
              for=""
              class="form-label"
            >Moderator for {{ area.areaName }}</label>
            <select
              id=""
              :name="`areas[${area.areaId}]['moderators']`"
              class="form-select select2"
              @change="saveModerator($event, area.areaId)"
            >
              <option
                disabled
                :selected="!area.moderatorId"
              >
                Select a moderator
              </option>
              <option
                v-for="moderator in moderators"
                :key="moderator.id"
                :value="moderator.id"
                :selected="area.moderatorId == moderator.id"
              >
                {{ moderator.name }}
              </option>
            </select>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {
      selectedAreas: [],
      selectedModerators: [],
      selectedChilds: [],
    }
  },
  computed: {
    ...mapGetters({
      areas: 'areas/areas',
      convo: 'convo/convo',
      moderators: 'users/moderators',
    }),
  },
  async mounted() {
    await this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    await this.$store.dispatch('users/fetchModerators')
    if (this.convo.areas) {
      this.selectedAreas = this.convo.areas.filter(e => {
        if (!e.parent_id) {
          return true
        }

        return false
      })
    }
  },
  methods: {
    selectArea(areaId, areaName, parentId = null) {
      if (parentId) {
        const area = this.selectedAreas.find(x => x.areaId === parentId)
        if (area === undefined) {
          document.getElementById(`area-${parentId}`).checked = true
          this.selectArea(parentId, areaName)
        }

        const child = this.selectedChilds.find(x => x.areaId === areaId)
        if (child === undefined) {
          this.selectedChilds.push({ areaId, areaName })
        } else {
          this.selectedChilds.splice(this.selectedChilds.findIndex(x => x.areaId === areaId), 1)
        }
      } else {
        const area = this.selectedAreas.find(x => x.areaId === areaId)
        if (area !== undefined && area.areaId) {
          this.selectedAreas.splice(this.selectedAreas.findIndex(x => x.areaId === areaId), 1)
          $(`.area-${areaId}-children`).prop('checked', false)
        } else {
          this.selectedAreas.push({ areaId, areaName, moderatorId: 1 })
        }
      }

      this.$emit('saveAnswer', { id: 'areas', data: this.selectedAreas })
      this.$emit('saveAnswer', { id: 'areas_child', data: this.selectedChilds })
    },
    saveModerator(event, areaId) {
      this.selectedModerators.push({ areaId, moderatorId: event.target.value })
      this.$emit('saveAnswer', { id: 'moderators', data: this.selectedModerators })
    },
  },
}
</script>
